<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8 mb-2">
    <div class="col-4">
      <!--
          gradientToColors: ["#f94144", "#f8961e", "#f9c74f", "#577590", "#277da1", "#43aa8b", "#90be6d"], -->
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#8ac926"
        chart-height="400"
        widgetTitle="P10"
        widgetSubtitle="All assets"
        note=""
        chartValue="67"
        fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-4">
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#ff9f1c"
        chart-height="400"
        widgetTitle="P50"
        widgetSubtitle="All assets"
        note=""
        chartValue="50"
        fontSize="35"
      ></WidgetPercent>
    </div>
    <div class="col-4">
      <WidgetPercent
        widget-classes="mb-5 pb-10"
        chart-color="#980000"
        chart-height="400"
        widgetTitle="P90"
        widgetSubtitle="All assets"
        note=""
        chartValue="14"
        fontSize="35"
      ></WidgetPercent>
    </div>
  </div>
  <div class="row gy-5 g-xl-8">
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="success"
        chart-height="100"
        widgetTitle="P10 estimated cost"
        widgetSubtitle="All assets"
        note=""
        chartValue="58 547 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="warning"
        chart-height="100"
        widgetTitle="P50 estimated cost"
        widgetSubtitle="All assets"
        note=""
        chartValue="159 784 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="danger"
        chart-height="100"
        widgetTitle="P90 estimated cost"
        widgetSubtitle="All assets"
        note=""
        chartValue="278 545 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
    <div class="col-3">
      <WidgetNumber
        widget-classes="card-xl-stretch-100 mb-xl-8"
        chart-color="info"
        chart-height="100"
        widgetTitle="Existing stock cost"
        widgetSubtitle="All assets"
        note=""
        chartValue="1 397 120 AZN"
        fontSize="30"
      ></WidgetNumber>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WidgetPercent from "@/components/widgets/mixed/WidgetPercent.vue";
import WidgetNumber from "@/components/widgets/mixed/WidgetNumber.vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "iaap-dashboard",
  components: {
    WidgetPercent,
    WidgetNumber
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Inventory analysis and planning");
      setCurrentPageBreadcrumbs("Supply chain management", []);
      setCurrentPageToolbarType("iaap");
    });
  }
});
</script>
