
import { defineComponent, onMounted, onBeforeMount } from "vue";
import WidgetPercent from "@/components/widgets/mixed/WidgetPercent.vue";
import WidgetNumber from "@/components/widgets/mixed/WidgetNumber.vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "iaap-dashboard",
  components: {
    WidgetPercent,
    WidgetNumber
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Inventory analysis and planning");
      setCurrentPageBreadcrumbs("Supply chain management", []);
      setCurrentPageToolbarType("iaap");
    });
  }
});
