
import { defineComponent, ref } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-number",
  components: {
  },
  props: {
    widgetTitle: String,
    widgetSubtitle: String,
    note: String,
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    chartValue: Number,
    fontSize: Number
  },
  setup(props) {
    const color = ref(props.chartColor);

    const labelColor = getCSSVariableValue("--bs-" + color.value);

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        height: props.chartHeight,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColor,
              fontSize: props.fontSize+'px',
              fontWeight: "500",
              offsetY: 12,
              show: true,
              formatter: function(val) {
                return val;
              }
            }
          },
          track: {
            background: 'transparent',
            strokeWidth: "0%"
          }
        }
      },
      colors: ['transparent'],
      stroke: {
        show: false,
        width: 0
      },
      labels: [" "]
    };

    const series = [props.chartValue];

    return {
      series,
      chartOptions
    };
  }
});
