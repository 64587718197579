<template>
  <!--begin::Mixed Widget 4-->
  <div :class="widgetClasses" class="card">
    <!--begin::Beader-->
    <div class="card-header border-0 py-5 d-block">
      <h3 class="card-title align-items-center flex-column">
        <span class="card-label fw-bolder fs-3 mb-1 me-0 text-center">{{ widgetTitle }}</span>
        <span class="text-muted fw-bold fs-7 text-center">{{widgetSubtitle}}</span>
      </h3>

      <div class="card-toolbar d-none">
        <!--begin::Menu-->
        <button
          type="button"
          class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg
              src="media/icons/duotone/Layout/Layout-4-blocks-2.svg"
            />
          </span>
        </button>
        <Dropdown1></Dropdown1>
        <!--end::Menu-->
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body d-flex flex-column" style="max-height: 200px; overflow: hidden; padding-top: 0;">
      <div class="flex-grow-1">
        <!--begin::Chart-->
        <apexchart
          class="mixed-widget-4-chart"
          :options="chartOptions"
          :series="series"
          :height="chartHeight"
          type="radialBar"
        ></apexchart>
        <!--end::Chart-->
      </div>

      <div class="pt-5" v-if="note">
        <p class="text-center fs-6 pb-5 ">
          <span class="badge badge-light-danger fs-8">Notes:</span>&nbsp;
          {{ note }}
        </p>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 4-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-percent",
  components: {
  },
  props: {
    widgetTitle: String,
    widgetSubtitle: String,
    note: String,
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    chartValue: Number,
    fontSize: Number
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-" + "gray-700");

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        height: props.chartHeight,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#f2f2f2',
            startAngle: -90,
            endAngle: 90,
            strokeWidth: "100%"
          },
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColor,
              fontSize: props.fontSize+'px',
              fontWeight: "700",
              offsetY: 0,
              show: true,
              formatter: function(val) {
                return val + "%";
              }
            }
          }
          /*track: {
            background: lightColor,
            strokeWidth: "100%"
          }*/
        }
      },
      colors: [props.chartColor],
      stroke: {
        lineCap: "round"
      },
      labels: [" "]
    };

    const series = [props.chartValue];

    return {
      series,
      chartOptions
    };
  }
});
</script>
