
import { defineComponent, ref } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-percent",
  components: {
  },
  props: {
    widgetTitle: String,
    widgetSubtitle: String,
    note: String,
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    chartValue: Number,
    fontSize: Number
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-" + "gray-700");

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        height: props.chartHeight,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: '#f2f2f2',
            startAngle: -90,
            endAngle: 90,
            strokeWidth: "100%"
          },
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColor,
              fontSize: props.fontSize+'px',
              fontWeight: "700",
              offsetY: 0,
              show: true,
              formatter: function(val) {
                return val + "%";
              }
            }
          }
          /*track: {
            background: lightColor,
            strokeWidth: "100%"
          }*/
        }
      },
      colors: [props.chartColor],
      stroke: {
        lineCap: "round"
      },
      labels: [" "]
    };

    const series = [props.chartValue];

    return {
      series,
      chartOptions
    };
  }
});
